import logo from './logo.svg';
import './App.css';

//var logo_url = 'https://atomic-republic.s3.amazonaws.com/atomic-6-web-files/ATOMIC-6-Logo-BW.png';
var logo_url = 'https://atomic-republic-atomic6.b-cdn.net/ATOMIC-6-Logo-BW.png';

//style={{height:'100px"'}}

function App() {


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo_url} alt="logo" className="App-logo"/>
      </header>
    </div>
  );
}

export default App;
